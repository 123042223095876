import MDRouter from "@/service/router";
import {
// eslint-disable-next-line no-unused-vars
Image as VImage,
// eslint-disable-next-line no-unused-vars
Progress as VProgress,
// eslint-disable-next-line no-unused-vars
CountDown, showDialog } from "vant";
import Error from "@@/common/Error.vue";
import domain from "@/service/domain";
import { userInfo, buildProductList, follow, getCpCode } from "@api/index";
import { mapState } from "vuex";
import CenterList from "@@/home/centerList.vue";
import { env } from "md-base-tools/env";
import { getSchemeUrl } from "@/utils/toolsApi";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Error,
    CenterList
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl,
      userIcon: state => state.user.userIcon,
      userId: state => state.user.userId,
      platform: state => state.user.platform
    }),
    ifOwner() {
      return this.userId && this.owner && this.owner.id && this.userId == this.owner.id ? true : false;
    },
    ifWxApp() {
      return env.isWxApp();
    },
    productContent() {
      if (this.user_info && this.user_info.cards) {
        return this.user_info.cards.filter(e => e.type === 5).length ? this.user_info.cards.filter(e => e.type === 5)[0].num : 0;
      } else {
        return 0;
      }
    }
  },
  data() {
    return {
      loading: true,
      is_next: true,
      isShow: true,
      detail: {},
      owner: {},
      file: {},
      pall: false,
      showError: false,
      authInfoType: false,
      orderListShow: false,
      message: "",
      op_type: 0,
      nft_num: 0,
      stock_hash: "",
      receive_code: "",
      status: "",
      status_name: "",
      user_info: {},
      orderData: {},
      productList: [],
      toUserid: "",
      page: 1,
      showPopup: false,
      // 积分领取弹窗
      code: '' // 待领取积分
    };
  },
  async mounted() {
    this.toUserid = this.$route.params.id;

    // eslint-disable-next-line no-undef
    userHeader.titleText("TA的主页");
    await this.userInfo();
    await this.buildProductList();
  },
  methods: {
    cardFn(item) {
      if (item.type == 3) {
        MDRouter.goLikePro({
          user_id: this.toUserid
        });
      } else if (item.type == 1) {
        MDRouter.goSupportProject({
          user_id: this.toUserid
        });
      } else if (item.type == 2) {
        // 看好创意
        MDRouter.goSupportProject({
          user_id: this.toUserid,
          type: 2
        });
      } else if (item.type == 7) {
        location.href = `${domain.wap}/product/pop/${item.item_id}.html`;
      }
    },
    async like(type) {
      if (type) {
        showDialog({
          title: "确定不再关注Ta吗?",
          message: ""
        }).then(async () => {
          // on confirm
          const {
            status
          } = await follow({
            to_user_id: this.toUserid,
            type: type ? 1 : 2
          });
          if (status == 0) {
            this.user_info.is_follow = false;
            mdToast(type ? "取消成功" : "关注成功");
          }
        }).catch(() => {
          // on cancel
          throw new Error();
        });
      } else {
        const {
          status
        } = await follow({
          to_user_id: this.toUserid,
          type: type ? 1 : 2
        });
        if (status == 0) {
          this.user_info.is_follow = true;
          mdToast(type ? "取消成功" : "关注成功");
        }
      }
    },
    clickpall() {
      this.pall = !this.pall;
    },
    toOrder(type) {
      location.href = `${domain.wap}/order/order_list?nav=${type}`;
    },
    toEvaluate() {
      location.href = `${domain.wap}/product/evaluate/list`;
    },
    async getNext(e) {
      if (e === 1) {
        this.productList = [];
      }
      this.page = e;
      if (this.is_next || e === 1) {
        await this.buildProductList();
      }
    },
    async buildProductList() {
      const {
        status,
        data
      } = await buildProductList({
        // user_id: this.toUserid,
        to_user_id: this.toUserid,
        page: this.page,
        page_size: 10
      });
      if (status == 0) {
        this.is_next = data.is_next;
        this.productList.push(...data.list);
      }
    },
    async userInfo() {
      const {
        data,
        status
      } = await userInfo({
        to_user_id: this.toUserid,
        user_id: this.toUserid
      });
      if (status == 0) {
        this.user_info = data;

        // 判断是否是CP
        const query = this.$route.query;
        if (data.is_cp && query.c) {
          this.checkCode();
        }
      }
      this.$nextTick(() => {
        this.showError = true;
      });
    },
    async checkCode() {
      const query = this.$route.query;
      if (query.c) {
        const {
          data
        } = await getCpCode({
          code: query.c
        });
        // if (status == 0) {
        if (data) {
          this.code = data;
          this.showPopup = true;
        }
        // }
      }
    },
    // 去小程序领取积分
    async goToMina() {
      let url = "";
      url = await this.getScheme();
      if (url) {
        location.href = url;
      }
    },
    async getScheme() {
      let str = window.location.search ? window.location.search.substr(1) : "";
      const param = {
        path: "pages/userPages/user/user",
        query: `user_id=${this.toUserid}&${str}`
      };
      const option = {
        jump_wxa: JSON.stringify(param)
      };
      const url = await getSchemeUrl(option);
      if (!url) return false;
      return url;
    }
  }
};