import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-53e8ebb2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "list"
};
const _hoisted_2 = ["onClick"];
import MDRouter from "@/service/router";
// eslint-disable-next-line no-unused-vars
import projectCard from "@@/common/projectCard.vue";
// eslint-disable-next-line no-unused-vars

import { List,
// eslint-disable-next-line no-unused-vars
Progress as VProgress,
// eslint-disable-next-line no-unused-vars
CountDown } from "vant";
import { reactive } from "vue";
export default {
  props: {
    list: {
      type: Array || Object,
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["getlist"],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const state = reactive({
      page: 2,
      refreshing: false,
      loading: false,
      finished: false,
      fit: "cover",
      timer: null
    });

    // 翻页
    const onLoad = () => {
      console.log('触发刷新2--->');
      if (state.refreshing) {
        state.page = 1;
        state.refreshing = false;
      }
      if (state.loading) {
        state.loading = false;
      }
      state.timer;
      clearTimeout(state.timer);
      state.timer = setTimeout(() => {
        emit("getlist", state.page);
        state.page++;
      }, 100);
    };
    // 下拉刷新
    const onRefresh = () => {
      console.log('触发刷新--->');
      state.finished = false;
      state.loading = false;
      onLoad();
    };
    // 去详情
    const goToInfo = item => {
      MDRouter.goProInfo(item.id);
    };
    // getFeedList();

    return (_ctx, _cache) => {
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      return _openBlock(), _createBlock(_component_van_pull_refresh, {
        modelValue: _unref(state).refreshing,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(state).refreshing = $event),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_unref(List), {
          loading: _unref(state).loading,
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => _unref(state).loading = $event),
          finished: _unref(state).finished,
          onLoad: onLoad
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.list, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              class: "item",
              key: index,
              onClick: $event => goToInfo(item)
            }, [_createVNode(projectCard, {
              item: item
            }, null, 8, ["item"])], 8, _hoisted_2);
          }), 128))])]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};