import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2234bbaa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "itemPro"
};
const _hoisted_2 = {
  key: 0,
  class: "recommended"
};
const _hoisted_3 = {
  class: "image"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "projectItemUserInfo"
};
const _hoisted_6 = {
  class: "icon"
};
const _hoisted_7 = ["src", "fit"];
const _hoisted_8 = {
  class: "name"
};
const _hoisted_9 = {
  key: 0,
  class: "tag"
};
const _hoisted_10 = {
  key: 1,
  class: "tag"
};
const _hoisted_11 = {
  class: "projectItemTitle"
};
const _hoisted_12 = {
  class: "projectItemStatus"
};
const _hoisted_13 = {
  key: 0
};
const _hoisted_14 = {
  key: 1
};
const _hoisted_15 = {
  key: 0
};
const _hoisted_16 = {
  key: 1
};
const _hoisted_17 = {
  key: 0,
  class: "backer_money ing"
};
const _hoisted_18 = {
  key: 1,
  class: "backer_count ing"
};
const _hoisted_19 = {
  class: "percent ing"
};
import { reactive } from "vue";
export default {
  props: {
    item: {
      type: Array || Object,
      default: {}
    },
    tab: {
      type: String,
      default: "itemPro"
    },
    category: {
      type: String,
      default: "all"
    },
    statusProp: {
      type: String,
      default: "0"
    }
  },
  setup(__props) {
    const props = __props;
    const state = reactive({
      item: props.item,
      category: props.category,
      status: props.statusProp,
      fit: "cover",
      list: [],
      page: 1,
      postCount: 0,
      repoint: false,
      default_logo: "https://s.moimg.net/m/img/placeholder/default_1x1@2x.png",
      default_img: "https://s.moimg.net/m/img/placeholder/default_21x9@2x.png"
    });
    return (_ctx, _cache) => {
      return props.tab == 'itemPro' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [__props.item.recommended == 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
        src: _ctx.$imgUrlFilter(__props.item.logo || __props.item.logo7x3, 690, 296) || _unref(state).default_img,
        onerror: "javascript:this.src='https://s.moimg.net/m/img/placeholder/default_21x9@2x.png'"
      }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
        src: _ctx.$imgUrlFilter(__props.item.user_info && __props.item.user_info.icon ? __props.item.user_info.icon : __props.item.user_icon, 38, 38) || _unref(state).default_logo,
        fit: _unref(state).fit,
        onerror: "javascript:this.src='https://s.moimg.net/m/img/placeholder/default_1x1@2x.png'"
      }, null, 8, _hoisted_7)]), _createElementVNode("p", _hoisted_8, _toDisplayString(__props.item.username || __props.item.user_info.username), 1), props.category == 'all' ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(__props.item.category), 1)) : _createCommentVNode("", true), props.statusProp == '0' || __props.item.state == '0' ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(__props.item.status_zh || __props.item.status), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_11, _toDisplayString(__props.item.short_title), 1), _createElementVNode("div", _hoisted_12, [__props.item.status_code == '1' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [__props.item.subscribe_count ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(__props.item.subscribe_count) + "人看好", 1)) : _createCommentVNode("", true), __props.item.start_time ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(__props.item.start_time) + "开始", 1)) : _createCommentVNode("", true)], 64)) : __props.item.status_code == '4' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [__props.item.bullish_count ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(__props.item.bullish_count) + "人看好", 1)) : _createCommentVNode("", true), __props.item.comment_num ? (_openBlock(), _createElementBlock("p", _hoisted_16, "评论 " + _toDisplayString(__props.item.comment_num), 1)) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 2
      }, [__props.item.backer_money ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(__props.item.backer_money), 1)) : _createCommentVNode("", true), __props.item.backer_count ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(__props.item.backer_count), 1)) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_19, _toDisplayString(__props.item.percent || __props.item.progress) + "%", 1)], 64))])])) : _createCommentVNode("", true);
    };
  }
};